<template>
  <div class="tiku-book">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">考点刷题：教育基础</div>
      <div class="right"></div>
    </div>
    <div class="book-list">
      <div class="book" v-for="(item, index) in exerciseBookList" :key="index">
        <div class="title">
          {{ item.title }}
        </div>
        <div v-if="item.report != null" class="report">
          正确率 {{ item.report.correct * 100 }}%, 得分 {{ item.report.score }}/ {{ item.report.totalScore }}
        </div>
        <div v-else class="tool">
          <button class="btn">刷题</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      exerciseBookList: [{ title: "练习册P1", report: { correct: 0.8, score: 8, totalScore: 10 } }],
    };
  },
  methods: {
    onGoBack() {
      this.$router.go(-1);
    },
    onGotoAppDownload(mobileType) {
      this.$router.push({
        name: "addToScreenStep",
        query: {
          type: mobileType,
        },
      });
    },
  },
  mounted() {
    for (let i = 0; i < 20; i++) {
      this.exerciseBookList.push({ title: "练习册P1", report: null });
    }
    // console.log(this.testData);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tiku-book {
  display: flex;
  flex-direction: column;
  background: rgb(241, 241, 241);
  height: 100%;
  align-items: center;
  .header {
    height: 62px;
    width: 100%;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 18px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .book-list {
    margin: 14px 0px 0px 0px;
    width: 344px;
    background: #ffffff;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: scroll;

    .book {
      width: 328px;
      height: 40px;
      margin: 7px 10px;
      background: #fafafa;
      border: 1px solid #fafafa;
      border-radius: 8px;
      border-radius: 8px;
      font-size: 14px;
      color: #323233;
      letter-spacing: 0.31px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title {
        margin: 12px 18px;
        font-size: 14px;
        color: #323233;
        letter-spacing: 0.31px;
      }
      .report {
        margin: 20px 18px 4px 0px;
        font-size: 11px;
        color: #8b8b8b;
        letter-spacing: 0.24px;
      }
      .tool {
        .btn {
					width: 46px;
					height: 24px;
          margin: 12px 18px;
          background: rgba(77, 82, 209, 0.9);
          border-radius: 8px;
          border-radius: 8px;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0.27px;
					border: none;
        }
      }
    }
  }
}
</style>
